<template>
<div class="tw-w-full">
  <Component v-bind:is="displayTerminalComponent()" />
</div>
</template>

<script>
import AllManifest from "@/components/transportCompany/manifest/AllManifest";
import SingleManifest from "@/components/transportCompany/manifest/SingleManifest";
import {mapGetters} from "vuex";
export default {
  name: "ViewManifestRoute",
  data(){
    return{

    }
  },
  computed: {
    ...mapGetters( "manifestComponent", ["currentComponent"] )
  },
  methods:{
    displayTerminalComponent() {
      let component = this.currentComponent
      if (component === "allManifest"){
        return AllManifest
      }
      else if (component === "singleManifest"){
        return SingleManifest
      }
    }
  },
  created() {
    let component = sessionStorage.getItem('manifestComponent')

    if (!component) {
      sessionStorage.setItem('manifestComponent', "allManifest")
      this.$store.dispatch("manifestComponent/setCurrentComponent", "allManifest");
      this.displayTerminalComponent()


    } else {
      this.$store.dispatch('manifestComponent/setCurrentComponent', component)
      this.displayTerminalComponent()
    }
  }
}
</script>

<style scoped lang="scss">

</style>