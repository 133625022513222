<template>
  <v-col sm="12">
    <loader
        :color="companyData.companyColor"
        :loading="loading"
        v-if="loading"
    />
    <v-col sm="12" class="tw-p-0 tw-flex-col" v-else>
      <div
          class="
          tw-flex tw-w-full tw-flex-col
          lg:tw-flex-row lg:tw-justify-start lg:tw-pb-5
          lg:tw-mt-0
          tw-mt-5
          table-container-div
          tw-p-0

        "
      >
        <icon-and-text
            :icon="allManifest"
            :title="manifests.length.toString()"
            desc="All Manifest"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '25%'"
            @clickAction="newBookingTab"
        />
        <icon-and-text class="lg:tw-mx-8"
            :icon="dailyManifest"
            :title="todayManifest.toString()"
            desc="Today's Manifest"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '25%'"
        />
        <image-and-text
            img="https://res.cloudinary.com/myroadpadi/image/upload/v1654161809/terminals_enhj93.png"
            :title="totalTerminals.toString()"
            desc="Total terminals"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '24%'"
        />

      </div>

      <div
          class="
          tw-flex tw-w-full tw-flex-col
          lg:tw-flex-row lg:tw-justify-between lg:tw-py-5
          tw-mt-20
          lg:tw-mt-5
          table-container-div
          tw-p-5
          lg:tw-pl-8
        "
          style="background: white"
      >
        <div class="table-container lg:tw-pr-8">
          <div
              class="
              tw-flex tw-flex-col
              lg:tw-flex-row lg:tw-justify-between lg:tw-items-center
            "
          >
            <p
                class="table-header"
                :style="{ color: companyData.companyColor }"
            >
              Manifest
            </p>
            <div
                class="
                tw-flex tw-flex-row tw-justify-end
                lg:tw-justify-center
                tw-items-center
              "
            >
              <div
                  class="mr-2"
                  style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                "
              >
                <v-text-field
                    placeholder="Search"
                    class="p-0"
                    solo
                    flat
                    hide-details
                    v-model="search"
                >
                  <template #prepend>
                    <v-icon :color="companyData.companyColor" class="ml-5">
                      mdi-magnify
                    </v-icon>
                  </template>
                  <template #append>
                    <v-icon :color="companyData.companyColor">
                      mdi-filter-variant
                    </v-icon>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>

          <v-data-table
              :headers="headers"
              v-model="selectedRow"
              :mobile-breakpoint="0"
              :search="search"
              item-key="id"
              :single-select="singleSelect"
              height="available"
              :hide-default-footer="manifests.length <= 10"
              :items="manifests"
              :no-data-text="not_found"
              class="mt-4 mx-1 table"
              :checkbox-color="companyData.companyColor"
              @click:row="handleViewAManifest"
          >
            <template #header.sno>
              <span class="status">S/N</span>
            </template>
            <template v-slot:[`item.sno`]="{ item }">
              <span class="td-style">{{ item.sno }}</span>
            </template>
            <template #header.vehicleNo>
              <span class="status">Vehicle Number</span>
            </template>
            <template v-slot:[`item.vehicleNo`]="{ item }">
              <span class="td-style text-capitalize">{{
                  item.trip.fleetForThisTrip.vehicleRegNumber
                }}</span>
            </template>
            <template #header.pilotName>
              <span class="status">Pilot's Name</span>
            </template>
            <template v-slot:[`item.pilotName`]="{ item }">
              <span class="td-style text-capitalize">{{
                  getPilotNames(item)
                }}</span>
            </template>


            <template #header.departure>
              <span class="status">Departure</span>
            </template>
            <template v-slot:[`item.departure`]="{ item }">
              <span class="td-style text-capitalize">{{ getDeparture(item) }}</span>
            </template>
            <template #header.arrival>
              <span class="status">Arrival</span>
            </template>
            <template v-slot:[`item.arrival`]="{ item }">
              <span class="td-style text-capitalize">{{ getDestination(item) }}</span>
            </template>

            <template #header.tripTime>
              <span class="status">Trip Time</span>
            </template>
            <template v-slot:[`item.tripTime`]="{ item }">
              <span class="td-style text-capitalize">{{
                  getTime(item.trip.takeOffDate, item.trip.takeOffTime)
                }}</span>
            </template>
            <template #header.tripDate>
              <span class="status">Trip Date</span>
            </template>
            <template v-slot:[`item.tripDate`]="{ item }">
              <span class="td-style text-capitalize">{{ item.trip.takeOffDate }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="companyData.companyColor"
                      >mdi-dots-horizontal</v-icon
                      >
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="">
                    <v-list-item-title
                        style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer"
                        @click="handleViewAManifest(item)"
                    >
                      <img
                          src="@/assets/icons/edit-icon.svg"
                          alt=""
                          class="tw-mr-2"
                      />
                      View
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-col>
  </v-col>
</template>

<script>
import IconAndText from "../IconAndText";
import allManifest from "@/assets/allManifest.svg"
import dailyManifest from "@/assets/dailyManifest.svg"
import locationImg from "@/assets/model.jpg";
import {getManifestByTransportCompanyId, getTransportCompanyTerminals} from "@/services/api/APIService";
import dayjs from "dayjs";
import Loader from "../../reuseables/Loader";
import ImageAndText from "@/components/transportCompany/ImageAndText";

export default {
  name: "AllManifest",
  components: {ImageAndText, Loader, IconAndText},
  data() {
    return {
      allManifest,
      dailyManifest,
      locationImg,
      headers: [
        { text: "", value: "sno" },
        { text: "", value: "vehicleNo" },
        { text: "", value: "pilotName" },
        { text: "", value: "departure" },
        { text: "", value: "arrival" },
        { text: "", value: "tripTime" },
        { text: "", value: "tripDate" },
        { text: "", value: "actions", align: "end" },
      ],
      not_found: "Manifests will display here",
      selectedRow: [],
      singleSelect: false,
      search: "",
      companyData: {},
      manifests: [],
      mostRecent: {},
      loading: false,
      todayManifest: 0,
      totalTerminals : 0
    };
  },
  methods: {
    selectEditTab() {
      this.$store.dispatch(
          "bookingComponent/setCurrentComponent",
          "editBooking"
      );
      sessionStorage.setItem("bookingComponent", "editBooking");
    },
    handleViewAManifest(manifest) {
      let staffData = JSON.parse(sessionStorage.getItem("staffData"))
      this.$store.dispatch("manifestComponent/setCurrentComponent", "singleManifest");
      sessionStorage.setItem('manifestComponent', "singleManifest")
      if (staffData){
        this.$router.push({name: 'SingleManifestCompanyStaff', params:{'manifestId': manifest.id}})
      }
      else {
        this.$router.push({name: 'SingleManifest', params:{'manifestId': manifest.id}})
      }

      sessionStorage.setItem("manifestId",manifest.id)
    },
    newBookingTab() {
      this.$store.dispatch(
          "bookingComponent/setCurrentComponent",
          "addBooking"
      );
      sessionStorage.setItem("bookingComponent", "addBooking");
    },
    getPilotNames(item) {
      return item.pilotFirstName+ " "+item.pilotLastName;
    },
    getDeparture(item){
      return item.trip.routeDepartureCity + " - "+ item.trip.routeDepartureTerminalName
    },
    getDestination(item){
      return item.trip.routeDestinationCity + " - "+ item.trip.routeDestinationTerminalName
    },
    getLastName(item) {
      return item.travellerDetail.lastName;
    },
    async getAllManifests() {
     await this.getAllTerminals()
      await getManifestByTransportCompanyId(this.companyData.id)
          .then((res) => {
            let staffData = JSON.parse(sessionStorage.getItem("staffData"))
            if (staffData){
              this.manifests = res.data.filter(manifest =>( manifest.trip.routeDepartureTerminalName === staffData.terminalAssignedName))
            }
            else {
              this.manifests = res.data
            }
            this.manifests.sort(function (a, b){
              return  dayjs(b.createdAt) - dayjs(a.createdAt)
            })
            this.manifests.forEach((manifest, index) => {
              manifest.sno = index+1
              if (dayjs().format('YYYY-MM-DD') === manifest.trip.takeOffDate){
                this.todayManifest += 1
              }
            });

            this.loading = false;
         })
          .catch((err) => {
            this.loading = false;
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          });
    },
    async getAllTerminals() {
      await getTransportCompanyTerminals(this.companyData.id)
          .then((res) => {
            this.totalTerminals = res.data.length;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          });
    },
    getTime(date, time) {
      return dayjs(date + time).format("LT");
    },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    if (companyData) {
      this.companyData = companyData;
    }
    await this.getAllManifests();
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.showings {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  span {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  // text-align: left;
  color: rgba(0, 74, 173, 1);
}

.main-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: available;
  padding: 0 !important;
}
.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 0;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 0;
}

::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}
</style>
